<template>
  <div class="d-flex flex-column">
    <ticket-card :id="ticketId"/>
    <discussion-list entity="ticket" :entity-id="ticketId"/>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'showTicket', params: {id: ticketId}})">
        {{ $t('btn.back_to_ticket') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import TicketCard from './components/TicketCard';
import DiscussionList from '@/components/comments/DiscussionList';

export default {
  components: {
    TicketCard,
    DiscussionList
  },
  data() {
    return {
      ticketId: null
    }
  },
  beforeMount() {
    this.ticketId = this.$route.params.id;
  }
}
</script>